import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Header from './Header';  // Importando o componente Header
import './AdminDashboard.css';

function AdminDashboard() {
  const [clientesSubmenuOpen, setClientesSubmenuOpen] = useState(false);
  const [valvulasSubmenuOpen, setValvulasSubmenuOpen] = useState(false);

const navigate = useNavigate();
function logout() {
  localStorage.clear();
  navigate("/login");
}

  // Defina o nome do usuário e a URL do avatar
  const userName = "Ana"; // Nome que deseja exibir no header
  const userAvatar = "/images/avatar.jpg"; // Caminho da imagem do avatar (garanta que a imagem esteja acessível na pasta 'public/images')

  return (
    <div className="admin-dashboard">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="logo">
          <img src="/assets/logo-seepil.png" alt="Seepil Logo" />
        </div>
        <ul className="sidebar-menu">
          <li>
            <button className="menu-button">
              <Link to="/admin/dashboard" className="menu-link">
                <img src="/assets/icon-home-page.png" alt="Ícone Início" className="menu-icon" />
                Início
              </Link>
            </button>
          </li>

          {/* Clientes Section */}
          <li>
            <button
              className="menu-button"
              onClick={() => setClientesSubmenuOpen(!clientesSubmenuOpen)}
            >
              <img src="/assets/icon-avatar.png" alt="Ícone Clientes" className="menu-icon" />
              Clientes
              <span className={`arrow ${clientesSubmenuOpen ? 'open' : ''}`}>&#9662;</span>
            </button>
            {clientesSubmenuOpen && (
              <ul className="submenu">
                <li>
                  <img src="/assets/next.png" alt="Ícone Clientes" className="submenu-icon" />
                  <Link to="/admin/cadastro-cliente">Criar Novo</Link>
                </li>
                <li>
                  <img src="/assets/next.png" alt="Ícone Clientes" className="submenu-icon" />
                  <Link to="/admin/clientes">Listar Clientes</Link>
                </li>
              </ul>
            )}
          </li>

          {/* Válvulas Section */}
          <li>
            <button
              className="menu-button"
              onClick={() => setValvulasSubmenuOpen(!valvulasSubmenuOpen)}
            >
              <img src="/assets/icon-copy.png" alt="Ícone Válvulas" className="menu-icon" />
              <span>Válvulas</span>
              <span className={`arrow ${valvulasSubmenuOpen ? 'open' : ''}`}>&#9662;</span>
            </button>
            {valvulasSubmenuOpen && (
              <ul className="submenu">
                <li><Link to="/admin/cadastro-valvula">Criar Nova</Link></li>
                <li><Link to="/admin/valvulas">Listar Válvulas</Link></li>
              </ul>
            )}
          </li>

          {/* Chamados Section */}
          <li>
            <button className="menu-button">
              <Link to="/admin/chamados">
                <img src="/assets/icon-chat.png" alt="Ícone Chamados" className="menu-icon" />
                <span className="menu-link">Chamados</span>
              </Link>
            </button>
          </li>

          {/* Logout */}
          <li>
            <button onClick={logout} className="menu-button">
              {/* <Link to="/logout" className="menu-link"> */}
                <img src="/assets/icon-log-out.png" alt="Ícone Logout" className="menu-icon" />
                Logout
              {/* </Link> */}
            </button>
          </li>
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="main-content">
        <Header userName={userName} userAvatar={userAvatar} />
        <div className="content">
          <h1>Bem-vindo a Seepil Valve Care</h1>
          <p>Selecione uma opção no menu à esquerda para criar ou listar seu cadastro de clientes e válvulas.</p>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
