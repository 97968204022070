import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';  // Import Routes e Route
import AdminDashboard from './components/admin/AdminDashboard';
import ClientForm from './components/admin/ClientForm';
import ClientList from './components/admin/ClientList';
import ValveForm from './components/admin/ValveForm';
import ValveList from './components/admin/ValveList';  // Importando o ValveList
import ClientDetails from './components/admin/ClientDetails';
import ValveDetails from './components/admin/ValveDetails'; 
import ClientDashboard from './components/cliente/ClientDashboard';
import Login from './components/Login';


function App() {
  return (
    <Router>


      <Routes>

      <Route path="/" element={<Navigate to={"/login"} />} />

        {/* Rota para a página de login */}
        <Route path="/login" element={<Login />} />

        {/* Rotas do Admin */}
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/cadastro-cliente" element={<ClientForm />} />
        <Route path="/admin/clientes" element={<ClientList />} />
        <Route path="/admin/cadastro-valvula" element={<ValveForm />} />
        <Route path="/admin/valvulas" element={<ValveList />} /> {/* Nova rota para listar válvulas */}
        <Route path="/admin/clientes/:id" element={<ClientDetails />} /> {/* Nova rota para os detalhes do cliente */}
        <Route path="/admin/valvulas/:id" element={<ValveDetails />} />

        {/* Rotas do Cliente */}
        <Route path="/cliente/dashboard" element={<ClientDashboard />} />

        {/* Rota padrão */}
       
      </Routes>
    </Router>
  );
}

export default App;
