import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importando o Axios para fazer as requisições
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar'; // Importando o Sidebar já existente
import Header from './Header';   // Importando o Header já existente
import './ValveList.css';

function ValveList() {
  const [valves, setValves] = useState([]); // Estado para armazenar as válvulas vindas do banco de dados

  // Função para buscar as válvulas do backend
  const fetchValves = async () => {
    try {
      const response = await axios.get('https://seepilpsvcare.com/api/valvulas-seepil'); // Rota da API para buscar válvulas
      setValves(response.data); // Armazena os dados de válvulas no estado
    } catch (error) {
      console.error('Erro ao buscar válvulas:', error);
    }
  };

  // Hook de efeito para carregar as válvulas ao carregar o componente
  useEffect(() => {
    fetchValves();
  }, []);

  return (
    <div className="admin-dashboard">
      <Sidebar /> {/* Sidebar */}
      <div className="main-content">
        <Header userName="Ana" userAvatar="/images/avatar.jpg" /> {/* Header */}
        <div className="valve-list-container">
          <h2>Válvulas Cadastradas</h2>
          <table className="valve-table">
            <thead>
              <tr>
                <th>Ação</th>
                <th>TAG</th>
                <th>Unidade</th>
                <th>Tipo de Válvula</th>
                <th>Fabricante</th>
                <th>Modelo</th>
                <th>Nº Série</th>
              </tr>
            </thead>
            <tbody>
              {valves.length > 0 ? (
                valves.map((valve) => (
                  <tr key={valve.id}>
                    <td>
                      <Link to={`/admin/valvulas/${valve.id}`}>
                        <img
                          src="/assets/icon-show.png"
                          alt="Visualizar válvula"
                          title="Visualizar válvula"
                          className="view-icon"
                        />
                      </Link>
                    </td>
                    <td>{valve.tag}</td>
                    <td>{valve.unidade}</td>
                    <td>{valve.tipoValvula}</td>
                    <td>{valve.fabricante}</td>
                    <td>{valve.modelo}</td>
                    <td>{valve.numeroSerie}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">Nenhuma válvula encontrada</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ValveList;
