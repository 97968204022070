import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import './ClientForm.css';
import axios from 'axios';

function ClientForm() {
  const [clientData, setClientData] = useState({
    nomeEmpresa: '',       // Nome da empresa
    cnpj: '',
    unidade: '',
    nomeUsuario: '',        // Nome do usuário
    loginUsuario: '',
    senhaUsuario: ''
  });

  const gerarLoginSenha = () => {
    const login = clientData.nomeUsuario.split(' ')[0].toLowerCase() + clientData.cnpj.slice(-4);
    const senha = Math.random().toString(36).slice(-8);
    setClientData({ ...clientData, loginUsuario: login, senhaUsuario: senha });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    gerarLoginSenha();
    try {
      const response = await axios.post('https://seepilpsvcare.com/api/clientes', clientData);
      alert(response.data.message);
      console.log('Resposta do servidor:', response.data);
    } catch (error) {
      console.error('Erro ao enviar dados ao backend:', error);
      alert('Erro ao cadastrar cliente. Verifique o console para mais detalhes.');
    }
  };

  return (
    <div className="admin-dashboard">
      <Sidebar />
      <div className="main-content">
        <Header userName="Ana" userAvatar="/images/avatar.jpg" />
        <div className="client-form-content">
          <div className="client-form-container">
            <h2>Cadastrar Novo Cliente</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Nome da Empresa:</label>
                <input
                  type="text"
                  name="nomeEmpresa"
                  value={clientData.nomeEmpresa}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>CNPJ:</label>
                <input
                  type="text"
                  name="cnpj"
                  value={clientData.cnpj}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Unidade:</label>
                <input
                  type="text"
                  name="unidade"
                  value={clientData.unidade}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Nome do Usuário:</label>
                <input
                  type="text"
                  name="nomeUsuario"
                  value={clientData.nomeUsuario}
                  onChange={handleChange}
                  required
                />
              </div>

              <button type="submit" className="btn-primary">Cadastrar Cliente</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientForm;
