import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Header from './Header';
import './ClientDetails.css';

function ClientDetails() {
  const { id } = useParams(); // Obtendo o ID do cliente pela URL
  const [client, setClient] = useState(null);

  // Função para buscar os detalhes do cliente usando useCallback para evitar problemas de dependência
  const fetchClientDetails = useCallback(async () => {
    try {
      const response = await axios.get(`https://seepilpsvcare.com/api/clientes/${id}`);
      setClient(response.data);
    } catch (error) {
      console.error('Erro ao buscar detalhes do cliente:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchClientDetails();
  }, [fetchClientDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await axios.put(`https://seepilpsvcare.com/api/clientes/${id}`, client);
      alert('Cliente atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar cliente:', error);
    }
  };

  if (!client) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="admin-dashboard">
      <Sidebar />
      <div className="main-content">
        <Header userName="Ana" userAvatar="/images/avatar.jpg" />
        <div className="client-details-container">
          <h2>Detalhes do Cliente</h2>
          <div className="form-group">
            <label>Nome:</label>
            <input
              type="text"
              name="nome"
              value={client.nome}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>CNPJ:</label>
            <input
              type="text"
              name="cnpj"
              value={client.cnpj}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Unidade:</label>
            <input
              type="text"
              name="unidade"
              value={client.unidade}
              onChange={handleInputChange}
            />
          </div>
          <button onClick={handleSave}>Salvar Alterações</button>
        </div>
        <div className="voltar">
            <a href="http://localhost:3000/admin/clientes">VOLTAR</a>
          </div>
      </div>
    </div>

    
  );
}

export default ClientDetails;
