import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Header from './Header';
import './ValveDetails.css';

function ValveDetails() {
  const { id } = useParams(); // Obtendo o ID da válvula pela URL
  const [valve, setValve] = useState(null);
  const [newFotosValvula, setNewFotosValvula] = useState([]);
  const [newFotosPlaqueta, setNewFotosPlaqueta] = useState([]);

  // Função para buscar os detalhes da válvula
  const fetchValveDetails = useCallback(async () => {
    try {
      const response = await axios.get(`https://seepilpsvcare.com/api/valvulas-seepil/${id}`);
      setValve(response.data);
    } catch (error) {
      console.error('Erro ao buscar detalhes da válvula:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchValveDetails();
  }, [fetchValveDetails]);

  if (!valve) {
    return <div>Carregando...</div>;
  }

  // Garantindo que fotoValvula e fotoPlaquetaFabricante são arrays
  const fotosValvula = Array.isArray(valve.fotoValvula) ? valve.fotoValvula : [valve.fotoValvula].filter(Boolean);
  const fotosPlaqueta = Array.isArray(valve.fotoPlaquetaFabricante) ? valve.fotoPlaquetaFabricante : [valve.fotoPlaquetaFabricante].filter(Boolean);

  // Função para lidar com mudanças nos campos
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValve((prevValve) => ({
      ...prevValve,
      [name]: value,
    }));
  };

  // Função para salvar alterações
  const handleSave = async () => {
    try {
      await axios.put(`https://seepilpsvcare.com/api/valvulas-seepil/${id}`, valve);
      alert('Válvula atualizada com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar válvula:', error);
    }
  };

  // Função para lidar com o upload de novas fotos
  const handleFileChange = (e, type) => {
    if (type === 'valvula') {
      setNewFotosValvula([...e.target.files]);
    } else if (type === 'plaqueta') {
      setNewFotosPlaqueta([...e.target.files]);
    }
  };

  // Função para enviar novas fotos
  const handleUpload = async (type) => {
    const formData = new FormData();
    const files = type === 'valvula' ? newFotosValvula : newFotosPlaqueta;

    files.forEach((file) => {
      formData.append('files', file);
    });

    try {
      await axios.post(`https://seepilpsvcare.com/api/valvulas-seepil/${id}/upload-${type}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Fotos carregadas com sucesso!');
      fetchValveDetails(); // Atualiza as informações da válvula
    } catch (error) {
      console.error('Erro ao carregar fotos:', error);
    }
  };

  return (
    <div className="admin-dashboard">
      <Sidebar />
      <div className="main-content">
        <Header userName="Ana" userAvatar="/images/avatar.jpg" />
        <div className="valve-details-container">
          <h2>Detalhes da Válvula</h2>
          {/* Campos Editáveis */}
          <div className="form-group">
            <label>TAG:</label>
            <input type="text" name="tag" value={valve.tag} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Cliente ID:</label>
            <input type="text" name="cliente_id" value={valve.cliente_id} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Tipo de Válvula:</label>
            <input type="text" name="tipoValvula" value={valve.tipoValvula} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Fabricante:</label>
            <input type="text" name="fabricante" value={valve.fabricante} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Modelo:</label>
            <input type="text" name="modelo" value={valve.modelo} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Número de Série:</label>
            <input type="text" name="numeroSerie" value={valve.numeroSerie} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Diâmetro Inlet:</label>
            <input type="text" name="diametroInlet" value={valve.diametroInlet} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Unidade Inlet:</label>
            <input type="text" name="unidadeInlet" value={valve.unidadeInlet} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Classe de Pressão Inlet:</label>
            <input type="text" name="classePressaoInlet" value={valve.classePressaoInlet} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Conexão Inlet:</label>
            <input type="text" name="conexaoInlet" value={valve.conexaoInlet} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Diâmetro Outlet:</label>
            <input type="text" name="diametroOutlet" value={valve.diametroOutlet} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Unidade Outlet:</label>
            <input type="text" name="unidadeOutlet" value={valve.unidadeOutlet} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Classe de Pressão Outlet:</label>
            <input type="text" name="classePressaoOutlet" value={valve.classePressaoOutlet} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Interface Outlet:</label>
            <input type="text" name="interfaceOutlet" value={valve.interfaceOutlet} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Orifício:</label>
            <input type="text" name="orificio" value={valve.orificio} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Fluido:</label>
            <input type="text" name="fluido" value={valve.fluido} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Temperatura de Operação:</label>
            <input type="text" name="temperaturaOperacao" value={valve.temperaturaOperacao} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Unidade de Temperatura:</label>
            <input type="text" name="unidadeTemperatura" value={valve.unidadeTemperatura} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Set Pressure:</label>
            <input type="text" name="setPressure" value={valve.setPressure} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Unidade Set Pressure:</label>
            <input type="text" name="unidadeSetPressure" value={valve.unidadeSetPressure} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>CDTP:</label>
            <input type="text" name="cdtp" value={valve.cdtp} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Unidade CDTP:</label>
            <input type="text" name="unidadeCdtp" value={valve.unidadeCdtp} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Contra Pressão:</label>
            <input type="text" name="contraPressao" value={valve.contraPressao} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Unidade Contra Pressão:</label>
            <input type="text" name="unidadeContraPressao" value={valve.unidadeContraPressao} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Equipamento Protegido:</label>
            <input type="text" name="equipamentoProtegido" value={valve.equipamentoProtegido} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Local de Instalação:</label>
            <input type="text" name="localInstalacao" value={valve.localInstalacao} onChange={handleInputChange} />
          </div>
          
          {/* Fotos da Válvula */}
          <div className="form-group">
            <label>Fotos da Válvula:</label>
            <div className="gallery">
              {fotosValvula.map((foto, index) => (
                <img
                  key={index}
                  src={`https://seepilpsvcare.com/uploads/${foto}`}
                  alt={`Foto Válvula ${index + 1}`}
                  className="thumbnail"
                  onClick={() => window.open(`https://seepilpsvcare.com/uploads/${foto}`, '_blank')}
                />
              ))}
            </div>
            <input type="file" multiple onChange={(e) => handleFileChange(e, 'valvula')} />
            <button onClick={() => handleUpload('valvula')} className='btn-upload'>Enviar</button>
          </div>

          {/* Fotos Plaqueta do Fabricante */}
          <div className="form-group">
            <label>Fotos Plaqueta do Fabricante:</label>
            <div className="gallery">
              {fotosPlaqueta.map((foto, index) => (
                <img
                  key={index}
                  src={`https://seepilpsvcare.com/uploads/${foto}`}
                  alt={`Foto Plaqueta ${index + 1}`}
                  className="thumbnail"
                  onClick={() => window.open(`https://seepilpsvcare.com/uploads/${foto}`, '_blank')}
                />



              ))}
            </div>
            <input type="file" multiple onChange={(e) => handleFileChange(e, 'plaqueta')} />
            <button onClick={() => handleUpload('plaqueta')} className='btn-upload'>Enviar</button>
          </div>

          <button onClick={handleSave} className="btn-primary">Salvar Alterações</button>
        </div>
      </div>
    </div>
  );
}

export default ValveDetails;
