import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importando useNavigate
import axios from 'axios';
import './Login.css';

function Login() {
  const [credentials, setCredentials] = useState({ login: '', senha: '' });
  const navigate = useNavigate(); // Criando uma instância do hook useNavigate

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', credentials);
      console.log('Login bem-sucedido', response.data);

      const usuario=response.data.user.nome
      localStorage.setItem("usuario-seepil", usuario)      
      
      // Redirecionar para o dashboard após login bem-sucedido
      navigate('/admin/dashboard'); 
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      alert('Login falhou! Verifique suas credenciais.');
    }
  };

  return (
    <div className="login-container">
      {/* Logo acima do formulário */}
      <img src="/assets/logo-branca1.png" alt="Seepil Logo" className="login-logo" />
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        <div className="form-group">
          <label>Login:</label>
          <input type="text" name="login" value={credentials.login} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Senha:</label>
          <input type="password" name="senha" value={credentials.senha} onChange={handleChange} required />
        </div>
        <button type="submit" className="btn-primary">Entrar</button>
      </form>
    </div>
  );
}

export default Login;
