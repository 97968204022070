import React, {useState, useEffect} from 'react'; //hook ---- useState armazena e useEffect puxa o que for armazenado (ele dispara uma ação quand a pagina carrega)
import './Header.css';

function Header({ userName, userAvatar }) {
  // Definindo avatar e nome padrão caso nenhum seja fornecido
  const avatarPath = userAvatar || '/images/avatar.jpg'; // Imagem padrão caso não seja fornecida
  const displayName = userName || 'Ana'; // Nome padrão "Ana" caso não seja fornecido
  const [nome, setNome] = useState("") // "" para string  , 0 para numeros [] para array
  
  useEffect(()=>{ 
    const usuario=localStorage.getItem("usuario-seepil") //nome q criei no localstorag
    setNome(usuario) 
  },[]) // deixando o array vazio ele só carrega uma vez

  return (
    <header className="header">
      <div className="header-left">
        <input
          type="text"
          placeholder="Pesquisar..."
          className="search-bar"
        />
      </div>
      <div className="header-right">
        <span className="user-name">{nome}</span>
        <img src={avatarPath} alt="Avatar do Usuário" className="user-avatar" />
      </div>
    </header>
  );
}

export default Header;
