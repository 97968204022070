import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Header from './Header';
import './ClientList.css';

function ClientList() {
  const [searchTerm, setSearchTerm] = useState('');
  const [clients, setClients] = useState([]);

  // Função para buscar os clientes do backend
  const fetchClients = async () => {
    try {
      const response = await axios.get('https://seepilpsvcare.com/api/clientes');
      setClients(response.data);
    } catch (error) {
      console.error('Erro ao buscar clientes:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredClients = clients.filter(client =>
    client.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.cnpj.includes(searchTerm)
  );

  return (
    <div className="admin-dashboard">
      <Sidebar />
      <div className="main-content">
        <Header userName="Ana" userAvatar="/images/avatar.jpg" />
        <div className="client-list-container">
          <h2>Clientes Cadastrados</h2>
          <input
            type="text"
            placeholder="Buscar por nome ou CNPJ"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <table className="client-table">
            <thead>
              <tr>
                <th>Ação</th>
                <th>Nome</th>
                <th>CNPJ</th>
                <th>Unidade</th> {/* Ajustado para Unidade */}
              </tr>
            </thead>
            <tbody>
              {filteredClients.length > 0 ? (
                filteredClients.map((client) => (
                  <tr key={client.id}>
                    <td>
                      <Link to={`/admin/clientes/${client.id}`}>
                        <img
                          src="/assets/icon-show.png"
                          alt="Visualizar cliente"
                          title="Visualizar cliente"
                          className="view-icon"
                        />
                      </Link>
                    </td>
                    <td>{client.nome}</td>
                    <td>{client.cnpj}</td>
                    <td>{client.unidade}</td> {/* Campo Unidade */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">Nenhum cliente encontrado</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ClientList;
